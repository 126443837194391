import React from "react";
import "./App.css";
import { Form, Input, Button } from "antd";

export default function App() {
  const [form] = Form.useForm();

  const submitForm = async ({ telegram_username }: any) => {
    await fetch("https://api.aminapirmanova.ru/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ telegram_username }),
    });
    form.resetFields();
  };

  return (
    <div className="container">
      <Form form={form} onFinish={submitForm}>
        <Form.Item name="telegram_username" label="Telegram Nickname">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
